// Helmet-Async
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

const MetaTags = ({title, description}) => {

	// const {pathname} = useLocation()

	// useEffect(() => {

	// }, [pathname])

	return (
		<Helmet>
			<title>StudioPM {title ? ` - ${title}` : ''}</title>
		</Helmet>
	)
}
export default MetaTags