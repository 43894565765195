// React
import {Link, useNavigate} from 'react-router-dom'

// Redux
import {useSelector, useDispatch} from 'react-redux'
import {logout} from '../../features/user/userSlice'

// Material UI
import {Alert, Box, Button, Drawer, Container, IconButton, InputAdornment, Stack, TextField, Typography} from '@mui/material'
import { useMediaQuery } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'

// Components
import MenuContent from './nav/MenuContent'

// Custom Style
const DrawerStyled = styled(Drawer)({
	// backgroundColor: "#e5bee6", 
	width: "240px",
})

const paperPropsStyles = {
	width: "240px",
	borderRight: "dashed 1px #ebe8e8"
	// bgcolor: "#e5bee6", // pink
	// borderRight: 0,
	// bgcolor: "#e1e3e1", // light grey
}



const Nav = ({isOpen, closeMenu}) => {

	// setting media queries
	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'))

	

	return (
		<>
			<DrawerStyled 
				variant="temporary" 
				open={isOpen} onClose={closeMenu}
				PaperProps={{ sx: paperPropsStyles }}
				anchor="right"
			>
				<MenuContent closeMenu={closeMenu} />
			</DrawerStyled>
		</>
	)
}
export default Nav