// React
import {Link, useNavigate} from 'react-router-dom'

// Redux
import {useSelector, useDispatch} from 'react-redux'
import {logout} from '../../../features/user/userSlice'

// Material UI
import {Alert, Box, Button, Drawer, Container, IconButton, InputAdornment, Stack, TextField, Typography} from '@mui/material'
import { styled } from '@mui/material/styles'

// Components
import AccountBox from "./AccountBox"
import LogoBox from "./LogoBox"
import LogOutButton from "./LogOutButton"
import ListBox from "./ListBox"

// Custom Styles
const ContentBox = styled(Box)({
	display: "flex",
	flexDirection: "column",
	padding: "0px 20px"
})



const MenuContent = ({closeMenu}) => {
	
	return (
		<ContentBox onClick={closeMenu}>

			{/* <LogoBox /> */}

			<AccountBox />

			<ListBox />

			{/* <LogOutButton /> */}

		</ContentBox>
	)
}
export default MenuContent