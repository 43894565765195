// React
import { Outlet, Navigate } from 'react-router-dom'

// Redux
import {useSelector, useDispatch} from 'react-redux'
import {logout} from '../features/user/userSlice'

// Toast
import { toast } from 'react-toastify'



const PrivateRoutes = ({roles}) => {

	const { user } = useSelector((state) => state.user)
	const dispatch = useDispatch()

	// console.log(`user`, user)

	// if not logged in, go to login page
	if (!user) {
		// toast.error('Not logged in.')
		return <Navigate to='/' />
	}

	// verify correct website
	if (user.website !== 'StudioPM') {
		// console.log(`user.website`, user.website)
		dispatch(logout())
		return <Navigate to='/' />
	}

	// if logged in and role matches, go to outlet. otherwise, go back to home page
	if (roles.includes(user.role)) {
		return <Outlet />
	} else {
		// toast.error('Not authorised to view this page.')
		// return <Navigate to='/dashboard' />
		return <Navigate to={`/crew/${user._id}`} /> // basically, if they are not authorised, we redirect back to the single user page
	}

}

export default PrivateRoutes