// React
import {useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'

// Redux
import {useSelector, useDispatch} from 'react-redux'
import {logout} from '../features/user/userSlice'

// Toastify
import {toast} from 'react-toastify'



const useErrorHandling = (error) => {
	
	const dispatch = useDispatch()
	const navigate = useNavigate()
	
	const checkError = (error) => {
		// console.log(`running on checkSessionExpiry`, error)
		if (error === 'Session expired' || error === 'Invalid token' || error === 'Not authorised') {
			dispatch(logout())
			navigate('/')
		}

		return toast.error(error)
	}

	return {checkError}


	// // if (error.response?.data?.message === 'Session expired') {
	// if (error === 'Session expired') {
	// 	console.log(`session expired`)
	// 	// return (navigate('/'))
	// 	// // userService.logout()
	// 	// return {logout}
	// }

	// const logout = () => {
	// 	navigate('/')
	// }

	// const dispatch = useDispatch()
	// const navigate = useNavigate()

	// // dispatch(logout())
	// navigate('/')

}

export default useErrorHandling