// Material UI
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles'
import {Alert, Avatar, Box, Button, Card, Chip, Container, Drawer, IconButton, InputAdornment, List, ListItem, ListItemButton, ListItemText, OutlinedInput, Stack, TextField, Toolbar, Typography} from '@mui/material'
import { useMediaQuery } from '@mui/material'

// https://mui.com/material-ui/customization/theming/
// https://mui.com/material-ui/customization/breakpoints/#custom-breakpoints

const customTheme = createTheme({
	breakpoints: {
		values: {
			// defaults
			// xs: 0,
			// sm: 600,
			// md: 900,
			// lg: 1200,
			// xl: 1536,

			mobile: 0,
			tablet: 900,
			laptop: 1200,
			desktop: 1500,
		},
	},
	typography: {
		fontFamily: 'Lato, sans-serif', // replace 'Aleo' with your font name
	},
	// palette: {
	// 	mode: 'dark',
	// 	primary: {
	// 		main: '#3f51b5',
	// 	},
	// 	secondary: {
	// 		main: '#f50057',
	// 	},
	// },
})

const BreakpointGuide = () => {

	const theme = useTheme()

	const isMobile = useMediaQuery(theme.breakpoints.only('mobile'))
	const isTablet = useMediaQuery(theme.breakpoints.only('tablet'))
	const isLaptop = useMediaQuery(theme.breakpoints.only('laptop'))
	const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'))

	return (
		<Box display="flex" justifyContent="flex-start" sx={{backgroundColor: "red", paddingLeft: "0px"}}>
			Guides on: 
			{isMobile === true && " Mobile"}
			{isTablet === true && " Tablet"}
			{isLaptop === true && " Laptop"}
			{isDesktop === true && " Desktop"}
		</Box>
	)
}

const CustomThemeProvider = ({children, guides}) => {
	
	return (
		<ThemeProvider theme={customTheme}>
			{guides && <BreakpointGuide /> }
			{children}
		</ThemeProvider>
	)
}
export default CustomThemeProvider