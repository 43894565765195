// Material UI
import {AppBar, Box, Container, IconButton, Slide, Stack, Toolbar, useScrollTrigger} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'



const MenuButton = ({openMenu}) => {

	return (
		<IconButton 
			color="inherit"
			sx={{mr: 1}}
			onClick={openMenu}
		>
			<MenuIcon />
		</IconButton>
	)
}
export default MenuButton