// React
import { useEffect } from 'react'

// Material UI
import { useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'



const useBreakpoints = () => {
	
	const theme = useTheme()

	
	const isMobile = useMediaQuery(theme.breakpoints.only('mobile'))
	const isTablet = useMediaQuery(theme.breakpoints.only('tablet'))
	const isLaptop = useMediaQuery(theme.breakpoints.only('laptop'))
	const isDesktop = useMediaQuery(theme.breakpoints.only('desktop'))

	// Report the window size
	// const windowReport = () => {

	// 	console.log(`isMobile`, isMobile)
	// 	console.log(`isTablet`, isTablet)
	// 	console.log(`isLaptop`, isLaptop)
	// 	console.log(`isDesktop`, isDesktop)

	// }
	
	// window.onresize = windowReport

	return {isMobile, isTablet, isLaptop, isDesktop}
	// to use:
	// const {isMobile, isTablet, isLaptop, isDesktop} = useBreakpoints()
}

export default useBreakpoints